import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  colors: {
    primaryBackground: "#f7fafc",
    secondaryBackground: "#ffffff",
    primaryHighlight: "#CDFF7A",
    secondaryHighlight: "#ee6c4d",
    text: "#000000",
    heading: "#000000",
    white: "#FFFFFF",
    lightGray: "#c6c6c6",
    warning: "#E53E3E",
    darkGray: "#6A6A7E",
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: "primaryHighlight",
          color: "black",
          _hover: { bg: "#8bde00" },
          _disabled: {
            bg: "primaryHighlight",
            cursor: "not-allowed",
            _hover: { bg: "primaryHighlight !important" },
          },
        },
        // ... rest of button variants
      },
    },
    // ... rest of component styles
  },
});

export default customTheme;
