import React from "react";
import { Box, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import logo from "../assets/logos/logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const logoSize = useBreakpointValue({ base: "150px", md: "150px" });

  // Get current language from URL or default to 'en'
  const currentLang = searchParams.get("lang") || "en";

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleLanguageChange = (newLang) => {
    // Update the URL query parameter while preserving other params
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("lang", newLang);
    setSearchParams(newSearchParams);
  };

  return (
    <Box as="nav" bg="white" boxShadow="sm" py={5} width="100%">
      <Flex
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={4}
      >
        <Box
          onClick={handleLogoClick}
          cursor="pointer"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={logo} alt="Logo" w={logoSize} h="auto" />
        </Box>

        <Flex align="center">
          <Box
            as="button"
            py={2}
            px={2}
            onClick={() => handleLanguageChange("en")}
            color={currentLang === "en" ? "black" : "gray.500"}
            fontWeight={currentLang === "en" ? "bold" : "normal"}
          >
            EN
          </Box>
          <Box px={0.5} color="gray.300">
            |
          </Box>
          <Box
            as="button"
            py={2}
            px={2}
            onClick={() => handleLanguageChange("fr")}
            color={currentLang === "fr" ? "black" : "gray.500"}
            fontWeight={currentLang === "fr" ? "bold" : "normal"}
          >
            FR
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
