import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import customTheme from "./theme/theme";
import BusinessesTermsPage from "./Pages/BusinessesTermsPage";
import PurchasersPrivacyPage from "./Pages/PurchasersPrivacyPage";
import PurchasersTermsPage from "./Pages/PurchasersTermsPage";

const App = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <Routes>
          <Route path="/terms/businesses" element={<BusinessesTermsPage />} />
          <Route path="/privacy/purchasers" element={<PurchasersPrivacyPage />} />
          <Route path="/terms/purchasers" element={<PurchasersTermsPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;