import React from 'react';
import { useSearchParams } from 'react-router-dom';
import MarkdownRenderer from '../MarkdownRenderer';
import Navbar from '../components/Navbar';
import { Box, Heading } from '@chakra-ui/react';

export default function PurchasersTermsPage() {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || 'en';

  return (
    <Box bg="#f6fafd" minH="100vh">
      <Navbar />
      <Box maxW="1200px" mx="auto" px={4} py={8}>
      
        <Box>
          <MarkdownRenderer filePath={`/docs/Markdown/purchasers_terms_${lang}.markdown`} />
        </Box>
      </Box>
    </Box>
  );
}